import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "../../../assets/jss/material-kit-react/views/aboutPageSections/informationStyle.js";

const useStyles = makeStyles(styles);

export default function InformationSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{backgroundColor: 'rgb(241, 244, 247)', paddingRight:20, paddingLeft: 20}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About Plasgos</h2>
          <div className={classes.description}>
            <p style={{textAlign:'left', fontSize:18}}>We are a marketplace company that focuses on the supplier market. We are trying to help businesses so that producers in Indonesia are more numerous and not dependent on imports.</p>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
