import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";

import styles from "../../assets/jss/material-kit-react/views/aboutPage.js";

// Sections for this page
import InformationSection from "./Sections/InformationSection.js";
import AboutSection from "./Sections/AboutSection.js";
import PositionSection from "./Sections/PositionSection.js";
import AddressSection from "./Sections/AddressSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop: 100}}>
        <div className={classes.container}>
          <InformationSection />
          <Divider />
          <PositionSection />
          <Divider />
          <AboutSection />
          <AddressSection/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
