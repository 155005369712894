import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import styles from "../../../assets/jss/material-kit-react/views/aboutPageSections/informationStyle.js";
const useStyles = makeStyles(styles);

export default function WhySection() {
  const classes = useStyles();
  const [digitalMarketingOpen, setDigitalMarketing] = React.useState(false);
  const [brandMarketingOpen, setBrandMarketing] = React.useState(false);
  const [mobileEngineerOpen, setMobileEngineer] = React.useState(false);
  const handleDigitalMarketingToggle = () => setDigitalMarketing(!digitalMarketingOpen);
  const handleBrandMarketingToggle = () => setBrandMarketing(!brandMarketingOpen);
  const handleMobileEngineerToggle = () => setMobileEngineer(!mobileEngineerOpen);
  return (
    <div className={classes.section}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Digital Marketing Strategist"
              titleStyle={{fontSize:16}}
              descriptionStyle={{fontSize:16, lineHeight: '1.2'}}
              description="Working closely with Digital Marketing Strategists, Digital Marketing Specialists, and Business team."
              icon={null}
              vertical
            />
            <Button variant="outlined" onClick={handleDigitalMarketingToggle} size="small">See Detail</Button>
            {
              digitalMarketingOpen && (
                <div style={{marginTop:25}}>
                  <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row'}}><h3 style={{fontSize:16}}>Requirements: </h3></div>
                  <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row', color:"#999999"}}>
                    <ul style={{textAlign:'left'}}>
                      <li>Experience in Digital Marketing at least 1 year</li>
                      <li>Experience in MAI, using AdMob or Fyber cross promotion</li>
                      <li>3 Proficient in negotiation and build relationship with partners.</li>
                      <li>Data-driven and performance-oriented.</li>
                      <li>Have a high analyzing skill.</li>
                    </ul>
                  </div>
                </div>
              )
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Senior Brand Marketing Specialist"
              titleStyle={{fontSize:16}}
              descriptionStyle={{fontSize:16, lineHeight: '1.2'}}
              description="Plan and develop brand communication strategies in order to increase the companys brand awareness."
              icon={null}
              vertical
            />
            <Button variant="outlined" onClick={handleBrandMarketingToggle} size="small">See Detail</Button>
            {
              brandMarketingOpen && (
                <div style={{marginTop:25}}>
                  <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row'}}><h3 style={{fontSize:16}}>Requirements: </h3></div>
                  <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row', color:"#999999"}}>
                    -
                  </div>
                </div>
              )
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Mobile Engineer - React Native"
              titleStyle={{fontSize:16}}
              descriptionStyle={{fontSize:16, lineHeight: '1.2'}}
              description="As a Mobile Engineer - React Native, you will bring solutions that change the lives of millions of users."
              icon={null}
              vertical
            />
            <Button variant="outlined" onClick={handleMobileEngineerToggle} size="small">See Detail</Button>
            {
              mobileEngineerOpen && (
                <div style={{marginTop:25}}>
                  <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row'}}><h3 style={{fontSize:16}}>Requirements: </h3></div>
                  <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row', color:"#999999"}}>
                    -
                  </div>
                </div>
              )
            }
          </GridItem>
        </GridContainer>
      </div>
      <div>
      </div>

    </div>
  );
}
